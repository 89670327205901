import React, { FC, useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import ReactResizeDetector from "react-resize-detector";
import * as special from "../images/special2.png";

import { colors, fontStacks, borders } from "../global-style";
import { WpHtmlContent } from "./wp-html-content";

interface Iprops {
  title: string;
  subtitle: string;
  content?: string;
}

type Inputs = {
  companyName: string;
  name: string;
  emailAddress: string;
  questionOrExtra: string;
};

export const Footer: FC<Iprops> = props => {
  const { title, subtitle, content }: Iprops = props;

  const leftEl = useRef<any>(null);
  const rightEl = useRef<any>(null);

  const [finger, setFinger] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted === true) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
        setIsDisabled(false);
        document.getElementById("contact-form").reset();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const { register, handleSubmit, errors } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    data.companyName === "Fuck you" &&
      data.name === "Fuck you" &&
      data.emailAddress === "FuckYou@FuckYou.Fuck" &&
      data.questionOrExtra === "Fuck you" &&
      setFinger(true);
    fetch("/form.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then(() => {
      setIsSubmitted(true);
      setIsDisabled(true);
    });
  };

  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        const leftX = leftEl.current
          ? leftEl.current.getBoundingClientRect().x
          : 0;

        const rightX = rightEl.current
          ? rightEl.current.getBoundingClientRect().x
          : 0;

        const isWrapped = leftX === rightX;

        return (
          <>
            <div
              style={{
                visibility: isSubmitted ? "visible" : "hidden",
                opacity: isSubmitted ? 1 : 0,
                transition: "visibility 0s, opacity 0.5s ease-in-out 0.2s",
                position: "fixed",
                height: "100%",
                width: "100%",
                backgroundColor: colors.aluminum,
                top: 0,
                left: 0,
                zIndex: 9,
                cursor: finger ? `url(${special}),auto` : "auto",
              }}
            >
              <div
                css={`
                  button {
                    background-color: ${colors.aluminum};
                    border: 1px solid ${colors.rhino};
                    padding: 0.5rem;
                  }

                  button:hover {
                    cursor: pointer;
                  }
                `}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translatex(-50%)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>
                  Wij hebben uw gegevens ontvangen en nemen contact met u op.
                </h1>
              </div>
            </div>

            <div
              style={{
                backgroundColor: colors.sandal,
                color: colors.superiorWhite,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  border: borders.red,
                  maxWidth: "83rem",
                  margin: "5rem 1.5rem",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-end",
                  justifyContent: isWrapped ? "center" : "space-between",
                }}
              >
                <div
                  ref={leftEl}
                  style={{
                    margin: "0.5rem",
                    flexBasis: "30rem",
                  }}
                >
                  <h1
                    className="sm-center"
                    style={{
                      fontFamily: fontStacks.merriweather,
                      marginBottom: "1rem",
                    }}
                  >
                    {title}
                  </h1>

                  <p className="sm-center" style={{ marginBottom: "1rem" }}>
                    {subtitle}
                  </p>

                  <div className="sm-center">
                    <WpHtmlContent htmlContent={content || ""} />
                  </div>
                </div>

                <div
                  id="contact"
                  ref={rightEl}
                  style={{
                    margin: "0.5rem",
                    flexBasis: "30rem",
                  }}
                >
                  <form
                    id="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ display: "flex", flexDirection: "column" }}
                    css={`
                      button {
                        background-color: ${colors.sandal};
                        color: ${colors.superiorWhite};
                        border: 1px solid ${colors.superiorWhite};
                        padding: 0.5rem;
                      }

                      button[disabled] {
                        cursor: not-allowed;
                        opacity: 0.5;
                      }

                      input[type="text"] {
                        width: 100%;
                        background-color: ${colors.sandal};
                        color: ${colors.superiorWhite};
                        border: 0;
                        margin-top: 1rem;
                        padding: 0.5rem 0rem;
                        border-bottom: 1px solid ${colors.superiorWhite};
                        outline-color: ${colors.sandal};
                        transition-property: outline, padding;
                        transition-duration: 0.3s;
                        transition-timing-function: ease;
                      }

                      input[type="text"]:last-of-type {
                        margin-bottom: 2rem;
                      }

                      input[type="text"]::placeholder {
                        color: ${colors.superiorWhite};
                      }

                      input[type="text"]:focus {
                        outline: 1px solid ${colors.superiorWhite};
                        outline-offset: 0px;
                        border-bottom-color: ${colors.sandal};
                        padding: 0.5rem;
                      }
                    `}
                  >
                    <input
                      ref={register({ required: true })}
                      name="companyName"
                      type="text"
                      placeholder="Bedrijfsnaam"
                    />

                    <input
                      ref={register({ required: true })}
                      name="name"
                      type="text"
                      placeholder="Naam"
                    />

                    <div
                      css={`
                        .errorPopup::after {
                          content: " ";
                          position: absolute;
                          bottom: 100%; /* At the bottom of the tooltip */
                          right: 75%;
                          border-width: 10px;
                          border-style: solid;
                          border-color: transparent transparent
                            ${colors.superiorWhite} transparent;
                        }
                      `}
                      style={{ position: "relative" }}
                    >
                      <input
                        ref={register({
                          required: true,
                          pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                        })}
                        name="emailAddress"
                        type="text"
                        placeholder="E-mailadres"
                      />
                      {errors.emailAddress &&
                        errors.emailAddress.type === "pattern" && (
                          <div
                            className="errorPopup"
                            style={{
                              position: "absolute",
                              bottom: "-1rem",
                              border: `1px solid ${colors.superiorWhite}`,
                              padding: "0.2rem",
                            }}
                          >
                            Ongeldig e-mailadres
                          </div>
                        )}
                    </div>

                    <div style={{ paddingTop: "1rem" }}>
                      <input
                        ref={register({ required: false })}
                        name="questionOrExtra"
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Vraag en/of opmerking"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button disabled={isDisabled} type={"submit"}>
                        Verzenden
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div //bottom nav
              style={{
                backgroundColor: colors.rhino,
                padding: "1.5rem",
                color: colors.superiorWhite,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flexBasis: "83rem",
                  display: "flex",
                  flexWrap: "wrap",
                  border: borders.red,
                }}
                css={`
                  @media (max-width: 768px) {
                    div:first-child {
                      display: none;
                    }
                    div:nth-of-type(2) {
                      text-align: center !important;
                    }
                  }
                `}
              >
                <div style={{ flexGrow: 1 }}>
                  <nav>
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      css={`
                        li + li {
                          margin-left: 1.5rem;
                        }
                      `}
                    >
                      <li>
                        <Link to="/nieuws">Pers</Link>
                      </li>

                      <li>
                        <Link to="/#scroll-2">Over</Link>
                      </li>

                      <li>
                        <Link to="/#scroll-5">Investeringen</Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div style={{ flexGrow: 1, textAlign: "end" }}>
                  &copy; {new Date().getFullYear()} Sandridge Investments -
                  website door webba
                </div>
              </div>
            </div>
          </>
        );
      }}
    </ReactResizeDetector>
  );
};
