import React, { FC, CSSProperties } from "react";

export const WpHtmlContent: FC<{
  htmlContent: string;
  style?: CSSProperties;
}> = props => {
  return (
    <div
      css={`
        * + * {
          margin-top: 0.5rem;
        }
      `}
      style={{ ...props.style }}
      dangerouslySetInnerHTML={{ __html: props.htmlContent }}
    />
  );
};
