// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("./../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */),
  "component---src-templates-investment-tsx": () => import("./../src/templates/investment.tsx" /* webpackChunkName: "component---src-templates-investment-tsx" */),
  "component---src-templates-newspage-tsx": () => import("./../src/templates/newspage.tsx" /* webpackChunkName: "component---src-templates-newspage-tsx" */),
  "component---src-templates-press-tsx": () => import("./../src/templates/press.tsx" /* webpackChunkName: "component---src-templates-press-tsx" */)
}

