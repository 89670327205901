import React, { FC, useState } from "react";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import ReactResizeDetector from "react-resize-detector";

import { GlobalStyle, colors, borders } from "../global-style";
import { Footer } from "./footer";
import Helmet from "react-helmet";

const Layout: FC<{ path: string }> = props => {
  const { children } = props;
  const { pathname } = useLocation();

  const data = useStaticQuery(graphql`
    query Logo {
      wp {
        globaal {
          globalCustomFields {
            footer {
              content
              subtitle
              title
            }
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 300, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <>
      <Helmet title={"Sandridge" || undefined}></Helmet>
      <GlobalStyle />
      <ReactResizeDetector handleWidth handleHeight>
        <div
          css={`
            @media (max-width: 768px) {
              .sm-center {
                text-align: center;
              }
              .spacing-between {
              }
            }
            .spacing-between {
            }
          `}
        >
          <header
            style={{
              width: "100%",
              backgroundColor: colors.superiorWhite,
              position: "sticky",
              top: 0,
              zIndex: 10,
              boxShadow: `0px -15px 30px ${colors.carbon}`,
            }}
            css={`
              @media (max-width: 1024px) {
                .top-nav {
                  margin-right: 0rem !important;
                  margin-bottom: 0rem !important;
                }
              }
              @media (min-width: 1025px) {
                .collapseMenu {
                  display: none !important;
                }
              }
            `}
          >
            <div
              className="top-nav"
              style={{
                marginLeft: "1rem",
                marginRight: "3rem",
                marginBottom: "12.5rem",
                padding: "1.5rem 0",
                display: "flex",
                alignItems: "center",
              }}
              css={`
                @media (max-width: 1024px) {
                  nav {
                    display: none;
                  }
                  .headerLogo {
                    width: 207px !important;
                  }
                  .hamburgerMenu {
                    display: block !important;
                  }
                }
              `}
            >
              <div style={{ flexGrow: 1 }}>
                <Link
                  to={"/"}
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <Img
                    className="headerLogo"
                    style={{
                      width: "268px",
                    }}
                    fluid={data.logo.childImageSharp.fluid}
                    objectFit="scale-down"
                  />
                </Link>
              </div>

              <nav
                style={{
                  marginLeft: "auto",
                  flexBasis: "15rem",
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    maxWidth: "inherit",
                  }}
                  css={`
                    li + li {
                      margin-left: 3rem;
                    }

                    li > a {
                      position: relative;
                      transition: all 0.2s ease;
                      top: 0;
                    }

                    li.active > a {
                      border-top: 5px solid ${colors.sandal};
                      padding-top: 2.5rem;
                      font-weight: 600;
                    }

                    li:hover > a {
                      top: -3px;
                    }

                    li.active:hover > a {
                      top: -3px;
                      padding-top: calc(2.5rem - 3px);
                    }
                  `}
                >
                  <li>
                    <Link
                      to={"/"}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/#scroll-2"}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      Verhaal
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/#scroll-3"}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      Aanpak
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/nieuws/"}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      Nieuws
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/#scroll-5"}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      Investeringen
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/#contact"}
                      style={{
                        padding: "0.5rem 2rem",
                        backgroundColor: colors.sandal,
                        color: colors.superiorWhite,
                      }}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>

              <div
                className="hamburgerMenu"
                style={{
                  display: "none",
                  margin: "auto 2rem",
                  width: "28px",
                  height: "24px",
                }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                <div
                  style={{
                    borderTop: `6px solid ${colors.sandal}`,
                    marginBottom: "3px",
                  }}
                ></div>
                <div
                  style={{
                    borderTop: `6px solid ${colors.sandal}`,
                    marginBottom: "3px",
                  }}
                ></div>
                <div
                  style={{
                    borderTop: `6px solid ${colors.sandal}`,
                  }}
                ></div>
              </div>
            </div>

            <div
              className="collapseMenu"
              style={{
                display: isCollapsed ? "none" : "block",
                position: "absolute",
                width: "100%",
                borderTop: `0.5rem solid ${colors.sandal}`,
                backgroundColor: colors.superiorWhite,
              }}
            >
              <ul
                style={{
                  maxWidth: "inherit",
                }}
                css={`
                  li {
                    padding: 1rem;
                    margin: 0 auto;
                  }

                  li.active {
                    font-weight: 600;
                    border-bottom: 0.25rem solid ${colors.sandal};
                  }
                `}
              >
                <li>
                  <Link
                    to={"/"}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    to={"/#scroll-2"}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    Verhaal
                  </Link>
                </li>

                <li>
                  <Link
                    to={"/#scroll-3"}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    Aanpak
                  </Link>
                </li>

                <li>
                  <Link
                    to={"/nieuws/"}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    Nieuws
                  </Link>
                </li>

                <li>
                  <Link
                    to={"/#scroll-5"}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    Investeringen
                  </Link>
                </li>

                <li>
                  <a
                    href="#contact"
                    style={{
                      padding: "0.5rem 2rem",
                      backgroundColor: colors.sandal,
                      color: colors.superiorWhite,
                    }}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </header>

          <div>
            <main>
              <div>{children}</div>
            </main>

            <footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textAlign: "center",
                    flexBasis: "30rem",
                    display: "flex",
                    justifyContent: "center",
                    border: borders.red,
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      flexBasis: "30rem",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ textAlign: "right", marginBottom: "5rem" }}>
                      Sandridge
                      <br />
                      Rietlandenstraat 78
                      <br />
                      7825 TT Emmen
                    </div>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      margin: "0 2rem",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        zIndex: 5,
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%)",
                        width: "2px",
                        height: "100%",
                        backgroundColor: colors.rhino,
                      }}
                    />
                    <div
                      style={{
                        zIndex: 5,
                        position: "absolute",
                        left: "50%",
                        top: "100%",
                        transform: "translate(-50%)",
                        width: "2px",
                        height: "3rem",
                        backgroundColor: colors.superiorWhite,
                      }}
                    />
                    <div
                      style={{
                        zIndex: 5,
                        position: "absolute",
                        left: "50%",
                        top: "calc(100% + 3rem)",
                        transform: "translate(-50%)",
                        width: "25px",
                        height: "25px",
                        borderRadius: "100%",
                        backgroundColor: colors.superiorWhite,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      flexBasis: "30rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ textAlign: "left", marginBottom: "5rem" }}>
                      Investments
                      <br />
                      06 - 57887284
                      <br />
                      info@sandridge.nl
                    </div>
                  </div>
                </div>
              </div>
              <Footer
                title={data.wp.globaal.globalCustomFields.footer.title || ""}
                subtitle={
                  data.wp.globaal.globalCustomFields.footer.subtitle || ""
                }
                content={
                  data.wp.globaal.globalCustomFields.footer.content || ""
                }
              />
            </footer>
          </div>
        </div>
      </ReactResizeDetector>
    </>
  );
};

export default Layout;
