import { createGlobalStyle, css } from "styled-components";

/**
 * Colors
 */

export const colors = {
  rhino: "#283f5e",
  sandal: "#af9775",
  aluminum: "#aab1bb",
  superiorWhite: "#fff",
  carbon: "#000",
};

/**
 * Typography
 */

export const fontStacks = {
  sourceSansPro: "'Source Sans Pro', sans-serif",
  merriweather: "'Merriweather', serif",
};

export const borders = {
  // green: "1px solid lime",
  // red: "1px solid coral",
  green: "0px solid lime",
  red: "0px solid coral",
};

export const measure = "100ch";

/**
 * Global styles
 */

export const GlobalStyle = createGlobalStyle`${css`
  /**
   * Use a better box model
   */

  /**
    * Set borders
    */

  * {
    // border: 0px !important;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /**
   *  scrollbar 
   */

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.sandal};
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #635137;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #473a26;
  }
  ::-webkit-scrollbar-track {
    background: #000000;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #000000;
  }
  ::-webkit-scrollbar-track:active {
    background: #000000;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  /**
   * Reset paddings and margins
   */

  * {
    margin: 0;
    padding: 0;
  }

  /**
   * Set base text style
   */

  :root {
    scroll-behavior: smooth;
    font-family: ${fontStacks.sourceSansPro};
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: ${colors.rhino};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /**
   * Set base line height
   */

  body {
    line-height: 1.75rem;
  }

  /**
   * Set measure
   */

  * {
    max-width: ${measure};
  }

  html,
  body,
  div,
  header,
  nav,
  main,
  footer,
  section,
  iframe,
  svg,
  img,
  input,
  canvas,
  button {
    max-width: none;
  }

  /**
   * Reset list styles
   */

  ul,
  ol,
  dd {
    list-style: none;
  }

  /**
   * Set image styles
   */

  img {
    display: block;
    max-width: 100%;
  }

  /**
   * Set SVG styles
   */

  svg {
    display: block;
  }

  /**
   * Reset link styles
   */

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /**
   * Centered content ul
   */

  .centered-content ul {
    list-style: initial;
    margin: 1rem 0 1rem 1rem;
  }
`}`;
